body {
  margin: 0;
  font-family: 'Copperplate', sans-serif;
  background-color: #07080B;
  color: white;
  font-weight: 100;
}

.test-btn {
  position: fixed;
  top: 0;
  left: 0;
}

.json {
  position: fixed;
  top: 50px;
  width: 100%;
  left: 0;
  height: 500px;
  overflow-y: scroll;
  background-color: white;
  color: black;
}

[data-role="wyng"] {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
}

[data-role="wyng"] span{
  color: white !important;
  font-family: 'Copperplate', sans-serif !important;
  font-weight: 100 !important;
}

.DirectUploadFormSection {
  max-width: 100% !important;
  max-height: 100% !important;
  margin: auto !important;
  left: 0;
  right: 0;
}

[data-role="wyng"] a{
  color: white !important;
  text-decoration: none;
}

[data-role="wyng"] button{
  background-color: var(--color-primary);
  color: white;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.webgl-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  user-select: none;
}

.transition {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: black;
  opacity: 0;
  transition: opacity 800ms ease;
}
.transition[data-active="true"] {
  opacity: 1;
}

h1, h2, h3, h4 {
  /* font-family: "sabbath-black", serif;
  font-weight: 800;
  font-style: normal; */
  text-transform: uppercase;
  font-weight: 400;
}

.btn {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 8px 16px;
  background-color: rgba(255,255,255,0);
  backdrop-filter: blur(5px);
  color: white;
  border: 2px solid white;
  font-size: 1em;
}

.btn--text {
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: inherit;
  border: none;
  outline: none;
  backdrop-filter: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  backdrop-filter: none;
}

.btn svg {
  pointer-events: none;
  color: inherit;
  fill: inherit;
  stroke: inherit;
  height: 1em;
}



.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0,0,0,0.2);
  user-select: none;
}

.screen__content {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 95%;
}

/* .screen[data-role="welcome"] .screen__content{
  margin-bottom: 200px;
} */

@media (min-width: 800px) {
  .screen__content { 
    max-width: 700px;
    gap: 5px;
  }
}

.screen__content h1, h2, h3, h4, h5{
  margin: 0;
}