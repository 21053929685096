.artWrapper {
  height: 50vh;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  user-select: none;
}

.artWrapper[data-view="grid"] div{
  flex: 0 1 30%;
}

.artWrapper[data-view="square"] div{
  flex: 0 1 100%;
}


.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.viewBtns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.zoomView {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.zoomImageWrap {
  flex: 1 1 auto;
  max-height: 50vh;
}
.zoomImageWrap img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.zoomLabel {
  font-size: 1.5em;
}