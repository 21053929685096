.wrapper {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  padding: 0px 4px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  user-select: none;
}

.wrapper[data-audioplayer="true"] {
  bottom: 60px;
}

.logo {
  width: 240px;
  margin-bottom: 6px;
  pointer-events: none;
}

.submitBtn {
  margin-bottom: 8px;
  display: none;
}
.wrapper[data-ui="scene"] .submitBtn {
  display: block;
}


.legal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.legal a{
  color: inherit;
  text-decoration: none;
}