.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  user-select: none;
  pointer-events: none;
}

.icon {
  height: 90px;
}
.icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 600px) {
  .icon {
    height: 80px;
  }
}

.label {
  font-size: 0.8em;
}

.value {
  font-size: 2.6em;
  color: var(--color-primary);
  font-family: "sabbath-black", serif;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 600px) {
  .value {
    font-size: 2.2em;
  }
}

@media (max-height: 700px) and (orientation: portrait) {
  .icon {
    height: 60px;
  }
  .label {
    font-size: 0.7em;
  }
  .value {
    font-size: 2em;
  }
}