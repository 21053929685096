.wrapper {
  position: relative;
  aspect-ratio: 1;
}

.wrapper img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}