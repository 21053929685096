.logo {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  margin: auto;
  height: 80px;
  width: 160px;
  user-select: none;
}
.logo svg{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.metric {
  position: absolute;
  top: 20px;
  user-select: none;
}
.metric[data-layout="left"] {
  left: 20px;
}
.metric[data-layout="right"] {
  right: 20px;
}

@media (max-width: 600px) {
  .metric[data-layout="left"] {
    left: 5px;
  }
  .metric[data-layout="right"] {
    right: 5px;
  }
}