.title {
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}
.titleMobile {
  display: none;
}
@media (max-width: 800px) {
  .title {
    display: none;
  }
  .titleMobile {
    display: block;
    max-width: 100%;
  }
}

.logo {
  max-height: 80px;
}

.cta {
  color: white;
  display: none;
}
.cta[data-active="true"] {
  opacity: 1;
  display: block;
}

.submitBtn {
  margin-top: 8px;
}